"use client"

import { Button } from "@/ui/button"
import { useRouter } from "next/navigation"

export default function NotFound(): JSX.Element {
  const router = useRouter()
  return (
    <div className="flex flex-1 flex-col items-center justify-center p-32 text-center">
      <div className="relative z-10 rounded-md border border-sr-border-primary bg-sr-bg-primary p-6">
        <h2 className="text-[128px] font-bold leading-[1]">404</h2>
        <h3 className="text-2xl">Page not found</h3>
        <Button
          className="mt-5 w-full"
          kind="primary"
          onClick={() => router.back()}
        >
          Go Back
        </Button>
      </div>
      <div className="dvd">
        <b></b>
      </div>
    </div>
  )
}
